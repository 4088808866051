<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <h3>Page loads count</h3>
    <p>
      This page was loaded {{ pageLoads }} times.
    </p>
    <h3>Another counter</h3>
    <p>
      Another counter has value: {{ counter }}
    </p>
    <h2>About</h2>
    <p>
      This is an example project for <a href="https://posobota.cz">#posobota</a> talk. More info in <a href="https://gitlab.com/apploud-public/posobota-deploy">project repository</a>.
    </p>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE = process.env.VUE_APP_API_BASE;

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      pageLoads: 0,
      counter: 0,
    };
  },
  async mounted() {
    const pageLoadsResponse = await axios.get(API_BASE + '/page-loads');
    this.pageLoads = pageLoadsResponse.data.count;
    const counterResponse = await axios.get(API_BASE + '/counter');
    this.counter = counterResponse.data.count;
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
